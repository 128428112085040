<script>
import appConfig from "@/app.config";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Planning",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {},
};
</script>

<template>
  <Layout>
    <div
      style="
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: -60px;
      "
    >
      <div class="d-flex justify-content-center">
        <p>
          Privacy policy :
          <a href="https://docs.web-cab.com/privacy-policy/">here</a>
        </p>
        <p></p>
      </div>
      <iframe
        src="https://web-cab.com/planning-assistant/"
        title="description"
        style="height: 100%; width: 100%; border: none"
      ></iframe>
    </div>
  </Layout>
</template>